import { mapActions, mapGetters } from 'vuex'

const Modal = () => import(/* webpackChunkName = "c-modal" */'@/components/Modal')
const emptyField = (name) => `${name} tidak boleh kosong`

export default {
  name: 'AddImagesForm',
  Components: {
    Modal
  },
  data () {
    return {
      name: '',
      type: '',
      errors: [],
      previewImage: null,
      errorImage: null
    }
  },
  computed: {
    ...mapGetters('user', ['token']),
    requestData () {
      return {
        name: this.name,
        type: this.type,
        image: this.previewImage,
        token: this.token
      }
    },
    errorsText () {
      return this.errors.join(', ')
    }
  },
  methods: {
    ...mapActions('image', ['createImagesAction', 'getImagesAction']),
    close () {
      this.$emit('close')
    },
    handleSubmit () {
      this.validateForm()
      if (this.errors.length === 0 && this.errorImage === null) {
        this.createImagesAction({
          data: this.requestData,
          success: this.handleSuccessSubmit
        })
      }
    },
    handleSuccessSubmit () {
      this.$swal({
        icon: 'success',
        title: 'Success Submit',
        showConfirmButton: false,
        timer: 1500
      })
      this.getImagesAction({
        success: () => {}
      })
      this.close()
    },
    validateForm () {
      this.errors = []
      if (this.name === '') this.errors.push(emptyField('Name'))
      else if (this.name.endsWith('.png') || this.name.endsWith('.jpg') || this.name.endsWith('.jpeg')) {
        return
      } else this.errors.push('nama gambar harus di akhiri dengan .jpg, .png atau .jpeg')
      if (this.type === '') this.errors.push(emptyField('Type'))
    },
    handleInputImage (e) {
      this.previewImage = null
      const file = e.target.files[0] || e.dataTransfer.files[0]
      this.uploadImageValidation(file)
      if (this.errorImage) return
      const reader = new FileReader()
      reader.onload = e => {
        this.previewImage = e.target.result
      }
      reader.readAsDataURL(file)
    },
    uploadImageValidation (file) {
      const fileType = file.type.toLowerCase()
      if (file.size > 100000) {
        this.errorImage = 'image size more than 100Kb. compress first!'
      } else if (['image/jpg', 'image/png', 'image/jpeg'].includes(fileType) !== true) {
        this.errorImage = 'wrong format image!'
      } else {
        this.errorImage = null
      }
    }
  }
}
